<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="exam_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('exam_date')">
                            <select-date type="text" v-model="formData.exam_date"
                                         :validation-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="start_time" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_time')">
                            <time-picker type="text" :is-range="false" v-model="formData.start_time"
                                         :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="end_time" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('end_time')">
                            <time-picker type="text" :is-range="false" v-model="formData.end_time"
                                         :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="is_exam_time_lesson" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('is_exam_time_lesson')">
                            <true-false-selectbox v-model="formData.is_exam_time_lesson"
                                                  :option="option"
                                                  :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="is_additional_classroom" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('is_additional_classroom')">
                            <true-false-selectbox v-model="formData.is_additional_classroom"
                                                  :option="option"
                                                  :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <Validation-provider name="type" rules="required"
                                         v-slot="{valid,errors}">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox
                                v-model="formData.type"
                                code="exam_schedules_types"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </Validation-provider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="section_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('courses')">
                            <multi-selectbox type="text"
                                             v-model="formData.section_id"
                                             :options="sectionOption"
                                             :multiple="false"
                                             :close-on-select="false"
                                             :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('explanation')">
                            <b-form-textarea type="text" v-model="formData.explanation"/>
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="d-flex justify-content-center mt-2">
            <b-button variant="primary"
                      @click="createForm"
                      :disabled="formLoading">
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

import SelectDate from "@/components/interactive-fields/SelectDate.vue";
import TimePicker from "@/components/elements/TimePicker.vue";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox.vue";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox.vue";

import ExamRequest from "@/services/ExamRequest";
import SectionService from "@/services/SectionService";

import qs from "qs";



export default {
    components: {
        MultiSelectbox,
        SelectDate,
        TimePicker,
        ParameterSelectbox,
        TrueFalseSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            formLoading: false,
            formData: {
                section_id: null,
                exam_date: null,
                start_time: null,
                end_time: null,
                is_exam_time_lesson: null,
                is_additional_classroom: null,
                explanation: null,
                type: null,
            },
            option: [
                {value: null, text: ''},
                {value: 1, text: this.$t('yes')},
                {value: 0, text: this.$t('no')}
            ],
            sectionOption: []
        }
    },
    created() {
        let config = {
            params:{limit:-1},
            paramsSerializer:(params)=> qs.stringify(params,{encode:false})
        }
        SectionService.getAll(config)
            .then(res => {
                const data = res.data.data;
                data.map(item => {
                    this.sectionOption.push({
                        value: item['id'],
                        text: item.course_code + '/' +item.course_name +'-'+ 'Section' +'/'+ item.section
                    })
                })
            })
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;
                ExamRequest.store(this.formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })

            }
        }
    }
}
</script>
